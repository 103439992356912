const DotSpinner = () => (
    <div style={dotWrapper}>
      <div style={dot}></div>
      <div style={dot}></div>
      <div style={dot}></div>
      <p style={dotText}>Loading, please wait...</p>
    </div>
  );
  
  const dotWrapper = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    justifyContent: 'center',
    backgroundColor: '#f9f9f9',
  };
  
  const dot = {
    width: '12px',
    height: '12px',
    margin: '5px',
    backgroundColor: '#007bff',
    borderRadius: '50%',
    animation: 'bounce 1.2s infinite ease-in-out',
  };
  
  const dotText = {
    marginTop: '10px',
    fontSize: '16px',
    color: '#333',
  };
  
  export default DotSpinner;
import React from 'react';

const Footer = () => {
    return (
        <footer className="light-footer skin-light-footer style-2">
            <div className="footer-middle">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                            <div className="footer_widget">
                                <img src="/assets/img/logo.png" className="img-footer small mb-2" alt="Company Logo" />
                                
                                <address className="mt-2">
                                Find Your Local Experts, <br/>Anytime, Anywhere <br />
                                LocalBizs.com  </address>
                                <div className="contact-info mt-3">
                                    <p>Phone: <a href="tel:">Coming Soon</a></p>
                                    <p>Email: <a href="mailto:support@LocalBizs.com">support@LocalBizs.com</a></p>
                                </div>
                                <div className="social-links mt-2">
                                    <ul className="list-inline">
                                        <li className="list-inline-item"><a href="#" className="theme-cl" aria-label="Facebook"><i className="lni lni-facebook-filled"></i></a></li>
                                        <li className="list-inline-item"><a href="#" className="theme-cl" aria-label="Twitter"><i className="lni lni-twitter-filled"></i></a></li>
                                        <li className="list-inline-item"><a href="#" className="theme-cl" aria-label="YouTube"><i className="lni lni-youtube"></i></a></li>
                                        <li className="list-inline-item"><a href="#" className="theme-cl" aria-label="Instagram"><i className="lni lni-instagram-filled"></i></a></li>
                                        <li className="list-inline-item"><a href="#" className="theme-cl" aria-label="LinkedIn"><i className="lni lni-linkedin-original"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                            <div className="footer_widget">
                                <h4 className="widget_title">Main Navigation</h4>
                                <ul className="footer-menu">
                                    <li><a href="#">Explore Listings</a></li>
                                    <li><a href="#">Browse Authors</a></li>
                                    <li><a href="#">Submit Listings</a></li>
                                    <li><a href="#">Shortlisted</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                            <div className="footer_widget">
                                <h4 className="widget_title">Business Owners</h4>
                                <ul className="footer-menu">
                                    <li><a href="#">Browse Categories</a></li>
                                    <li><a href="#">Payment Links</a></li>
                                    <li><a href="#">Saved Places</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                            <div className="footer_widget">
                                <h4 className="widget_title">About Company</h4>
                                <ul className="footer-menu">
                                    <li><a href="#">Who We Are?</a></li>
                                    <li><a href="#">Our Mission</a></li>
                                    <li><a href="#">Our Team</a></li>
                                    <li><a href="#">Packages</a></li>
                                </ul>
                            </div>
                        </div> */}

                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                            <div className="footer_widget">
                                <h4 className="widget_title">Helpful Topics</h4>
                                <ul className="footer-menu">
                                    <li><a href="/terms-condtions">Terms & Condtions</a></li>
                                    <li><a href="/about-us">AboutUs</a></li>
                                    <li><a href="/contact-us">ContactUs</a></li>
                                    {/* <li><a href="#">FAQ's Page</a></li> */}
                                    <li><a href="/privacy-policy">Privacy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="footer-bottom br-top">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12 text-center">
                        © 2024 <a href="https://www.LocalBizs.com" target="_blank" rel="noopener noreferrer">www.LocalBizs.com</a>. Designed By <a href="https://LocalBizs.com" target="_blank" rel="noopener noreferrer">LocalBizs</a>.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
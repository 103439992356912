import React, { useState, useEffect } from 'react';
import { useLocation, Link, useParams } from 'react-router-dom'; // Import useParams
import DarkHeader from './DarkHeader';
import CatsFilters from './CatFilters';
import { Helmet } from 'react-helmet'; // Import Helmet
import Header from './Header';
import NewHeader from './NewHeader';
import BeautifulSpinner from './BeautifulSpinner';
import DotSpinner from './DotSpinner';
import ReadMore from './ReadMore';


const BusinessListing = () => {
    const [businesses, setBusinesses] = useState([]); // Initialize as an array
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const location = useLocation(); // Get the location object
    const { location: locationParam, query, category = '' } = useParams(); // E
    const processedQuery = query.toLowerCase().replace(/-/g, ' ').trim();

    const [loading, setLoading] = useState(true); // New state



    useEffect(() => {
        const fetchBusinesses = async () => {
            setLoading(true); // Start loading

            try {
                 const response = await fetch(`https://api.localbizs.com/api/search/all?query=${encodeURIComponent(processedQuery)}&location=${encodeURIComponent(locationParam)}&category=${encodeURIComponent(category)}&pageNumber=${currentPage}&pageSize=${itemsPerPage}`, {
                // const response = await fetch(`http://localhost:5294/api/search/all?query=${encodeURIComponent(processedQuery)}&location=${encodeURIComponent(locationParam)}&category=${encodeURIComponent(category)}&pageNumber=${currentPage}&pageSize=${itemsPerPage}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    mode: 'cors' // explicitly set mode to CORS
                });
                const data = await response.json();
                setBusinesses(data.$values);
                setTotalPages(data.totalPages || 0);
            } catch (error) {
                console.error('Error fetching businesses:', error);
            }finally {
                setLoading(false); // End loading
            }
        };

        fetchBusinesses();
    }, [currentPage, itemsPerPage, query, locationParam, category]);

    // Logic for pagination
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const title = `Best ${category || 'Businesses'} in ${locationParam || 'Your Location'} - Page ${currentPage}`;
    const description = `Explore top ${category || 'businesses'} in ${locationParam || 'your area'} with ratings, reviews, and working hours. Find the best match for your needs.`;

    const keywords = `${category}, ${locationParam}, businesses, reviews, listings`;
    // Calculate page range
    const maxVisiblePages = 10; // Maximum number of visible pages
    const pageNumbers = [];

    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    // Adjust startPage if endPage is near totalPages
    if (endPage === totalPages) {
        startPage = Math.max(1, totalPages - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }
    // Function to handle business click
    // const handleBusinessClick = (businessDetailID) => {
    //     navigate(`/single-listing/${businessDetailID}`); // Redirect to SingleListingTemplate with business ID
    // };

    const generateSEOUrl = (location = '', name = '', id) => {
        const seoLocation = location
            .toLowerCase()
            .trim()                                 // Remove leading and trailing spaces
            .replace(/[^a-z0-9]+/g, '-')            // Replace all special characters and spaces with a hyphen
            .replace(/^-+|-+$/g, '');               // Remove leading and trailing hyphens

        const seoName = name
            .toLowerCase()
            .trim()
            .replace(/[^a-z0-9]+/g, '-')
            .replace(/^-+|-+$/g, '');               // Remove leading and trailing hyphens

        return `/bizs/${seoLocation}/${seoName}/${id}`;
    };

    return (
        <div id="main-wrapper">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="URL_TO_IMAGE" /> {/* Replace with an appropriate image URL */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content="URL_TO_IMAGE" /> {/* Replace with an appropriate image URL */}
                <link rel="canonical" href={window.location.href} />
                {/* <meta http-equiv="Content-Security-Policy" content="default-src 'self'; script-src 'self' 'unsafe-inline';" />
                <meta http-equiv="X-Content-Type-Options" content="nosniff" />
                <meta http-equiv="X-Frame-Options" content="deny" /> */}
            </Helmet>
            {loading ? (
                <DotSpinner />
            ) : (
                <>
                    <DarkHeader></DarkHeader>
                    <div class="clearfix"></div>
                    <CatsFilters></CatsFilters>

                    <section className="gray py-5">
                        <div className="container">
                            <div class="row">

                                {/* <div className="row justify-content-between align-items-center"> */}

                                {/* Item Wrap Start */}
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div className="row justify-content-center g-2">

                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                                    <li className="breadcrumb-item"><Link to="#">{locationParam}</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page">{category}</li>
                                                </ol>
                                            </nav>
                                            <div className="">
                                                <h1 className="ft-bold">The Best {category} { } in {locationParam}</h1>
                                            </div>
                                        </div>

                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                            <div className="d-block grouping-listings">
                                                <div className="d-block grouping-listings-title">
                                                    <h5 className="ft-medium mb-3">Sponsored Results</h5>
                                                </div>

                                                {/* Dynamic Business Listings */}
                                                {businesses.map(($values, index) => (
                                                    <div key={index} className="grouping-listings-single">
                                                        <div className="vrt-list-wrap">
                                                            <div className="vrt-list-wrap-head">
                                                                <div className="vrt-list-thumb">
                                                                    <div className="vrt-list-thumb-figure">
                                                                        <img src={`/images/images1/${$values.image1_URL}`} className="img-fluid" alt={$values.image1_Title} />                                                            </div>
                                                                </div>
                                                                <div className="vrt-list-content">
                                                                    {/* <h4 className="mb-0 ft-medium">
                                                            <span className="text-dark fs-md">

                                                                {/* <a href={$values.businessURL} className="text-dark fs-md" target="_blank" rel="noopener noreferrer"> */}
                                                                    {/* <Link to={`/single-listing/${$values.businessDetailID}`} className="text-dark fs-md">

                                                                        {$values.BusinessName}

                                                                        <span className="verified-badge">
                                                                            <i className="fas fa-check-circle"></i></span>
                                                                </Link>
                                                                </span>


                                                            </h4> */}
                                                                    <h4 className="mb-0 ft-medium">
                                                                        {/* <Link
                                                                    to={`/single-listing/${$values.businessDetailID}`}
                                                                    className="business-name-link"
                                                                >
                                                                    {$values.businessName}
                                                                    <span className="verified-badge">
                                                                        <i className="fas fa-check-circle"></i>
                                                                    </span>
                                                                </Link> */}
                                                                        <a
                                                                            href={generateSEOUrl($values.cityName, $values.name, $values.businessDetailID)}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            className="business-name-link"
                                                                        >
                                                                            {$values.businessName}
                                                                            <span className="verified-badge">
                                                                                <i className="fas fa-check-circle"></i>
                                                                            </span>
                                                                        </a>
                                                                    </h4>
                                                                    <div className="Goodup-ft-first">
                                                                        <div className="Goodup-rating">
                                                                            <div className="Goodup-rates">
                                                                                {Array.from({ length: 5 }, (_, i) => (
                                                                                    <i key={i} className={i < $values.rating ? "fas fa-star" : "far fa-star"}></i>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                        <div className="Goodup-price-range">
                                                                            <span className="small ft-medium">{$values.reviewCount} Reviews</span>
                                                                            <div className="d-inline ms-2">
                                                                                {Array.from({ length: $values.priceLevel || 0 }, (_, i) => (
                                                                                    <span key={i} className="active"><i className="fas fa-dollar-sign"></i></span>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="vrt-list-features mt-2 mb-2">
                                                                        <ul>
                                                                            {$values.specialties.split(',').map((specialty, index) => (
                                                                                <li key={index}>
                                                                                    <a href="javascript:void(0);">{specialty.trim()}</a>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                    <div className="vrt-list-sts">
                                                                        <h5 className="ft-bold mb-2">Working Hours:</h5>
                                                                        <ul className="working-hours-list">
                                                                            {$values.workingHours.split('\n').map((hours, index) => {
                                                                                const [day, time] = hours.split(':').map(item => item.trim());
                                                                                const isClosed = time && time.includes('Closed'); // Updated line

                                                                                return (
                                                                                    <li key={index} className="working-hours-item">
                                                                                        <span className="day-time">
                                                                                            <i className={`fas ${isClosed ? 'fa-times-circle' : 'fa-clock'} status-icon`}
                                                                                                style={{ color: isClosed ? 'red' : 'green' }}></i>
                                                                                            {day}
                                                                                        </span>
                                                                                        <span className={`time-range ${isClosed ? 'closed-status' : 'open-status'}`}>
                                                                                            {isClosed ? 'Closed' : time}
                                                                                        </span>
                                                                                    </li>
                                                                                );
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                    <div className="vrt-list-features mt-2 mb-2">
                                                                        {/* <p className="vrt-qgunke">{$values.description}</p> */}
                                                                        <ReadMore text={$values.description} />

                                                                    </div>
                                                                    <div className="vrt-list-amenties">
                                                                        <ul>
                                                                            {$values.insuranceAccepted.split(',').map((insurance, index) => (
                                                                                <li key={index}>
                                                                                    <div className="vrt-amens yes">
                                                                                        <span>{insurance.trim()}</span>
                                                                                    </div>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                    {/* Address Section */}
                                                                    <address className="mt-2 mb-2">
                                                                        {$values.address.replace(/\nDirections/, '')}
                                                                    </address>

                                                                    {/* Additional Information */}
                                                                    <div className="business-details">
                                                                        <p><strong>Phone:</strong> {$values.phoneNumber}</p>
                                                                        <p><strong>Email:</strong> {$values.email.split('|')[0]}</p>
                                                                        <p><strong>Website:</strong>
                                                                            <a href={$values.websiteURL.split('|')[1]} target="_blank" rel="noopener noreferrer">
                                                                                {$values.websiteURL.split('|')[0]}
                                                                            </a>
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                                {/* Pagination Section */}
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <ul className="pagination">
                                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                            <a className="page-link" href="#" aria-label="Previous" onClick={() => currentPage > 1 && paginate(currentPage - 1)}>
                                                                <span className="fas fa-arrow-circle-left"></span>
                                                                <span className="sr-only">Previous</span>
                                                            </a>
                                                        </li>
                                                        {pageNumbers.map(number => (
                                                            <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                                                                <a className="page-link" href="#" onClick={() => paginate(number)}>
                                                                    {number}
                                                                </a>
                                                            </li>
                                                        ))}
                                                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                            <a className="page-link" href="#" aria-label="Next" onClick={() => currentPage < totalPages && paginate(currentPage + 1)}>
                                                                <span className="fas fa-arrow-circle-right"></span>
                                                                <span className="sr-only">Next</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <p className="text-center">Page {currentPage} of {totalPages}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {/* Row */}


                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}

        </div>
    );
};

export default BusinessListing;
import React, { useEffect, useState } from 'react';
import DarkHeader from './DarkHeader';

const fetchWorkingProcess = async () => {
  return {
    title: "How It Works",
    subtitle: "Working Process",
    steps: [
      {
        icon: "ti-map-alt text-success",
        title: "Find Interesting Places",
        description: "Discover local businesses and attractions tailored to your preferences. Whether you’re looking for dining options, services, or recreational spots, our platform helps you find the perfect places to visit."
      },
      {
        icon: "ti-user theme-cl",
        title: "Contact Business Owners",
        description: "Engage with business owners directly. Our platform allows you to send inquiries or requests for more information, ensuring that you have all the details you need before making decisions."
      },
      {
        icon: "ti-shield text-sky",
        title: "Make a Reservation",
        description: "Once you’ve found the ideal spot, you can easily make reservations online. Our streamlined process ensures that you can secure your spot with minimal hassle, allowing you to enjoy your experience worry-free."
      }
    ]
  };
};

const AboutUs = () => {
  const [workingProcessData, setWorkingProcessData] = useState({ title: "", subtitle: "", steps: [] });

  useEffect(() => {
    const getWorkingProcessData = async () => {
      const data = await fetchWorkingProcess();
      setWorkingProcessData(data);
    };
    getWorkingProcessData();
  }, []);

  return (
    <div id="main-wrapper">
      <DarkHeader></DarkHeader>
      <section className="space min">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="sec_title position-relative text-center mb-5">
                <h6 className="mb-0 theme-cl">{workingProcessData.subtitle}</h6>
                <h2 className="ft-bold">{workingProcessData.title}</h2>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            {workingProcessData.steps.map((step, index) => (
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12" key={index}>
                <div className={`wrk-pro-box ${index === 0 ? 'first' : index === 1 ? 'sec' : 'thrd'}`}>
                  <div className="wrk-pro-box-icon"><i className={step.icon}></i></div>
                  <div className="wrk-pro-box-caption">
                    <h4>{step.title}</h4>
                    <p>{step.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
import React from 'react';

const BeautifulSpinner = () => (
  <div style={spinnerWrapper}>
    <div style={spinnerCircle}></div>
    <p style={spinnerText}>Loading, please wait...</p>
  </div>
);

const spinnerWrapper = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: '#f9f9f9', // Optional background color
};

const spinnerCircle = {
  border: '6px solid rgba(0, 0, 0, 0.1)', // Light gray border
  borderTop: '6px solid #007bff', // Highlighted blue border
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  animation: 'spin 1.2s linear infinite',
};

const spinnerText = {
  marginTop: '10px',
  fontSize: '16px',
  color: '#333',
  fontFamily: 'Arial, sans-serif',
};

export default BeautifulSpinner;
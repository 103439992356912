import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Contact from './components/Contact';
import Banner from './components/Banner';
import FeaturedListings from './components/FeaturedListings';
import CategoryListings from './components/CategoryListings';
import NewsletterForm from './components/NewsletterForm';
import BusinessListing from './components/BusinessListing';
import SingleListingTemplate from './components/SingleListingTemplate';
import { Helmet } from 'react-helmet';
import HomePage from './components/HomePage';
import PrivacyPolicy from './components/PrivacyPolicy';
import AboutUs from './components/AboutUs';
import CookieBanner from './components/CookieBanner';
import TermsAndConditions from './components/TermsAndConditions';

const App = () => {
    const location = useLocation();
    const navigate = useNavigate(); // For navigation

    // Sample listing data for the SingleListingTemplate
    const listingData = {
        bookingDetails: {
            checkIn: "2024-10-01",
            checkOut: "2024-10-05",
            adults: 2,
            kids: 1,
            advancedFeatures: [{ name: "Extra Bed", price: "$20" }],
            priceDetails: [
                { label: "Base Price", amount: "$100" },
                { label: "Cleaning Fee", amount: "$10" },
            ],
            totalPayment: "$110"
        },
        author: {
            name: "John Doe",
            location: "New York",
            image: "author-image-url",
            listingsCount: 10,
            rating: "4.5",
            followersCount: 2
        },
        business: {
            info: [
                { icon: "🍽️", title: "Cuisine", description: "Italian" },
                { icon: "🏆", title: "Rating", description: "4.5" },
            ]
        },
        relatedListings: [
            { title: "Listing 1", image: "listing1-url", status: "Available", rating: 4, reviews: 100, link: "#" },
            { title: "Listing 2", image: "listing2-url", status: "Sold", rating: 5, reviews: 50, link: "#" },
        ]
    };

    // Function to handle search and navigate to BusinessListing
    const handleSearch = async (query, location, category) => {
        // Dummy search functionality (you can replace it with real API calls)
        const results = [
            { businessName: "Nail Salon", description: "A lovely nail salon", cityName: "San Francisco" },
            { businessName: "Plumber Co.", description: "Reliable plumbing service", cityName: "New York" }
        ];

        // Pass the results and redirect to business listing
        navigate('/businesslisting', { state: { searchResults: results } });
    };

    return (
        <>
        <CookieBanner></CookieBanner>

            {/* Conditionally render components only on the Home page */}
            {/* {location.pathname === '/' && <Banner onSearch={handleSearch} />} Pass the search handler
            {location.pathname === '/' && <FeaturedListings />}
            {location.pathname === '/' && <CategoryListings />}
            {location.pathname === '/' && <NewsletterForm />} */}
            {/* Conditionally render HomePage on the home route */}
            {location.pathname === '/' && <HomePage onSearch={handleSearch} />}

   {/* <Header /> */}
            {/* Define routes for specific pages */}
            <Routes>
            <Route path='/home' element={<HomePage onSearch={handleSearch} />}/>

                {/* <Header /> */}
                {/* <Route path="/Header" element={<Header />} /> */}
                <Route path="/Home" element={<Home />} />
                <Route path="/Banner" element={<Banner />} />
                {/* <Route path="/search" element={<BusinessListing />} /> */}
                <Route path="/bizs/:location/:query" element={<BusinessListing />} />
                <Route path="/bizs/:location/:name/:businessDetailID" element={<SingleListingTemplate />} />
                {/* <Route path="/businesslisting" element={<BusinessListing />} /> */}
                {/* <Route path="/single-listing/:businessDetailID" element={<SingleListingTemplate />} /> */}

                {/* <Route path="/singlelistingtemplate" element={<SingleListingTemplate listingData={listingData} />} /> */}
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                <Route path="/about-us" element={<AboutUs/>}/>
                <Route path="/terms-condtions" element={<TermsAndConditions/>}/>

            </Routes>

            <Footer />
        </>
    );
};

const MainApp = () => (
    <Router>
        <App />
    </Router>
);

export default MainApp;
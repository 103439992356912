import React from 'react';

const categories = [
    {
        name: 'Restaurants',
        items: [
            { img: '/assets/img/icons/fast-delivery.png', label: 'Delivery' },
            { img: '/assets/img/icons/burger.png', label: 'Burgers' },
            { img: '/assets/img/icons/booking.png', label: 'Reservations' },
            { img: '/assets/img/icons/house.png', label: 'Japanese' },
            { img: '/assets/img/icons/chinese-food.png', label: 'Chinese' },
            { img: '/assets/img/icons/mexican-hat.png', label: 'Mexican' },
            { img: '/assets/img/icons/dish.png', label: 'Italian' },
            { img: '/assets/img/icons/tom-yum.png', label: 'Thai' },
        ],
    },
    {
        name: 'Home Services',
        items: [
            { img: '/assets/img/icons/hammer.png', label: 'Contractors' },
            { img: '/assets/img/icons/coconut-tree.png', label: 'Landscaping' },
            { img: '/assets/img/icons/broken-cable.png', label: 'Electricians' },
            { img: '/assets/img/icons/padlock.png', label: 'Locksmiths' },
            { img: '/assets/img/icons/basket.png', label: 'Home Cleaning' },
            { img: '/assets/img/icons/delivery-truck.png', label: 'Movers' },
            { img: '/assets/img/icons/home.png', label: 'HVAC' },
            { img: '/assets/img/icons/plumbering.png', label: 'Plumbers' },
        ],
    },
    {
        name: 'Auto Services',
        items: [
            { img: '/assets/img/icons/wrench.png', label: 'Auto Repairs' },
            { img: '/assets/img/icons/rental-car.png', label: 'Car Dealers' },
            { img: '/assets/img/icons/sketch.png', label: 'Auto Detailing' },
            { img: '/assets/img/icons/oil.png', label: 'Oil Change' },
            { img: '/assets/img/icons/shopping-bag.png', label: 'Body Shops' },
            { img: '/assets/img/icons/parking.png', label: 'Parking' },
            { img: '/assets/img/icons/car-wash.png', label: 'Car Wash' },
            { img: '/assets/img/icons/tow-truck.png', label: 'Towing' },
        ],
    },
    {
        name: 'More',
        items: [
            { img: '/assets/img/icons/towel-hanger.png', label: 'Dry Cleaning' },
            { img: '/assets/img/icons/salon.png', label: 'Hair Salons' },
            { img: '/assets/img/icons/smartphone.png', label: 'Phone Repair' },
            { img: '/assets/img/icons/weights.png', label: 'Gyms' },
            { img: '/assets/img/icons/cocktail.png', label: 'Bars & Cafe' },
            { img: '/assets/img/icons/spa.png', label: 'Massage' },
            { img: '/assets/img/icons/poinsettia.png', label: 'Nightlife' },
            { img: '/assets/img/icons/online-shopping.png', label: 'Shopping' },
        ],
    },
];

const CatsFilters = () => {
    return (
        <>
        <section className="cats-filters py-3">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                        <div className="Goodup-all-drp">
                            {categories.map((category, index) => (
                                <div className="Goodup-single-drp small" key={index}>
                                    <div className="btn-group">
                                        <button
                                            type="button"
                                            className="btn btn-secondary dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {category.name}
                                        </button>
                                        <ul className="dropdown-menu">
                                            {category.items.map((item, itemIndex) => (
                                                <li key={itemIndex}>
                                                    <a className="dropdown-item" href="#">
                                                        <img
                                                            src={item.img}
                                                            className="img-fluid"
                                                            width="20"
                                                            alt=""
                                                        />
                                                        {item.label}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                            <div className="Goodup-single-drp small">
                                <div className="btn-group">
                                    <button type="button" className="btn bg-dark text-light">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="clearfix"></div>
        </>
    );
};

export default CatsFilters;
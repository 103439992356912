import React from 'react';

const BusinessInfo = ({ businessinfo }) => {
    if (!businessinfo || !businessinfo.map) return null; // Prevents rendering if data is not present
  return (
    <div className="jb-apply-form bg-white rounded py-4 px-4 border mb-4">
      <div className="uli-list-info">
        <ul>
          {businessinfo.map((item, index) => (
            <li key={index}>
              <div className="list-uiyt">
                <div className="list-iobk">{item.icon}</div>
                <div className="list-uiyt-capt"><h5>{item.title}</h5><p>{item.description}</p></div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BusinessInfo;
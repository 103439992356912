import React from 'react';

const RelatedListings = ({ listings }) => {
    if (!listings || !listings.map) return null; // Prevents rendering if data is not present
      return (
        <section className="space min">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="sec_title position-relative text-center mb-5">
                            <h6 className="theme-cl mb-0">Related Listing</h6>
                            <h2 className="ft-bold">Recently Viewed Listing</h2>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    {listings.map((listing, index) => (
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={index}>
                            <div className="Goodup-grid-wrap">
                                <div className="Goodup-grid-upper">
                                    <div className="Goodup-pos ab-left">
                                        <div className={`Goodup-status ${listing.status.toLowerCase()} me-2`}>{listing.status}</div>
                                    </div>
                                    <div className="Goodup-grid-thumb">
                                        <a href={listing.link} className="d-block text-center m-auto">
                                            <img src={listing.image} className="img-fluid" alt={listing.title} />
                                        </a>
                                    </div>
                                    <div className="Goodup-rating overlay">
                                        <div className="Goodup-pr-average high">{listing.rating}</div>
                                        <div className="Goodup-aldeio">
                                            <div className="Goodup-rates">
                                                {[...Array(5)].map((_, starIndex) => (
                                                    <i key={starIndex} className={`fas fa-star ${starIndex < listing.rating ? 'text-warning' : 'text-muted'}`}></i>
                                                ))}
                                            </div>
                                            <div className="Goodup-all-review"><span>{listing.reviews} Reviews</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Goodup-grid-footer">
                                    <h5 className="ft-medium mb-0">{listing.title}</h5>
                                    <span className="ft-medium">{listing.location}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};
export default RelatedListings;
// src/components/Contact.js
import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import DarkHeader from './DarkHeader';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.localbizs.com/api/contact', formData);
            console.log('Contact saved:', response.data);
            // Optionally, you can reset the form or show a success message
            setFormData({
                name: '',
                email: '',
                mobile: '',
                subject: '',
                message: ''
            });
        } catch (error) {
            console.error('Error saving contact:', error);
            // Optionally, handle error (e.g., show a notification)
        }
    };

    return (
        <>
            <DarkHeader />
            <section className="gray">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="sec_title position-relative text-center">
                                <h2 className="off_title">Contact Us</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-start justify-content-center">
                        <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12">
                            <form className="row submit-form py-4 px-3 rounded bg-white mb-4" onSubmit={handleSubmit}>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="small text-dark ft-medium">Your Name *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Your Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="small text-dark ft-medium">Your Email *</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Your Email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="small text-dark ft-medium">Mobile</label>
                                        <input
                                            type="tel"
                                            className="form-control"
                                            placeholder="+91 256 548 7542"
                                            name="mobile"
                                            value={formData.mobile}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="small text-dark ft-medium">Subject</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Type Your Subject"
                                            name="subject"
                                            value={formData.subject}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="small text-dark ft-medium">Message</label>
                                        <textarea
                                            className="form-control ht-80"
                                            placeholder="Your Message..."
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            required
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <button type="submit" className="btn theme-bg text-light">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-12">
                                    <div className="bg-white rounded p-3 mb-2">
                                        <h4 className="ft-medium mb-3 theme-cl">Address info:</h4>
                                        <p>Find Your Local Experts,
                                        <br /> Anytime, Anywhere</p>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-12">
                                    <div className="bg-white rounded p-3 mb-2">
                                        <h4 className="ft-medium mb-3 theme-cl">Call Us:</h4>
                                        <h6 className="ft-medium mb-1">Customer Care:</h6>
                                        <p className="mb-2">Coming Soon</p>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-12">
                                    <div className="bg-white rounded p-3 mb-2">
                                        <h4 className="ft-medium mb-3 theme-cl">Drop A Mail:</h4>
                                        <p>Drop mail we will contact you within 24 hours.</p>
                                        <p className="lh-1 text-dark">support@localbizs.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Footer /> */}
        </>
    );
};

export default Contact;
import React from 'react';

const AuthorBox = ({ author }) => {
  return (
    <div className="jb-apply-form bg-white rounded py-4 px-4 border mb-4">
      <div className="Goodup-agent-blocks">
        <div className="Goodup-agent-thumb">
          <img src={author.image} width="90" className="img-fluid circle" alt={author.name} />
        </div>
        <div className="Goodup-agent-caption">
          <h4 className="ft-medium mb-0">{author.name}</h4>
          <span className="agd-location"><i className="lni lni-map-marker me-1"></i>{author.location}</span>
        </div>
        <div className="clearfix"></div>
      </div>
      <div className="Goodup-iuky">
        <ul>
          <li>{author.listingsCount}+<span>Listings</span></li>
          <li><div className="text-success">{author.rating}</div><span>Ratings</span></li>
          <li>{author.followersCount}K<span>Followers</span></li>
        </ul>
      </div>
      <div className="agent-cnt-info">
        <div className="row g-4">
          <div className="col-6">
            <a href="javascript:void(0);" className="adv-btn full-width">Follow Now</a>
          </div>
          <div className="col-6">
            <a href="javascript:void(0);" className="adv-btn full-width">Send Message</a>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <a href="javascript:void(0);" className="adv-btn full-width theme-bg text-light">View Profile</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorBox;
import React, { useEffect, useState } from 'react';
import DarkHeader from './DarkHeader';

// Mock fetch function to simulate API call
const fetchPrivacyPolicy = async () => {
    return {
        title: "Privacy & Policy",
        content: [
            {
                heading: "Privacy Policy for Localbizs",
                body: [
                    "Last Updated: [09.09.2024]",
                    "Welcome to Localbizs! Your privacy is important to us, and this Privacy Policy explains how we collect, use, and safeguard your information when you visit our website, www.localbizs.com. By using our website, you agree to the terms outlined here."
                ]
            },
            {
                heading: "1. Information We Collect",
                body: [
                    "We may collect both personally identifiable information (PII) and non-personally identifiable information (Non-PII) when you interact with our website. This includes:",
                    "• Personal Information: When you sign up for an account, subscribe to our newsletter, or contact us, we may collect personal information such as your name, email address, phone number, and other details.",
                    "• Usage Data: We automatically collect information about your device and usage patterns, such as your IP address, browser type, the pages you visit, and the date/time of your visit."
                ]
            },
            {
                heading: "2. How We Use Your Information",
                body: [
                    "The information we collect may be used for:",
                    "• Providing and improving our services: To offer better user experiences and optimize our website functionality.",
                    "• Communication: To respond to inquiries, send updates, or provide information related to your interests.",
                    "• Marketing and promotions: To inform you about new features, services, or promotions we believe you may find valuable.",
                    "• Analytics and research: To track trends, usage, and engagement on our site."
                ]
            },
            {
                heading: "3. Cookies and Tracking Technologies",
                body: [
                    "We use cookies and similar tracking technologies to collect information and enhance your experience on our site. You can manage cookie preferences through your browser settings, though some features may not function fully without cookies."
                ]
            },
            {
                heading: "4. GDPR Compliance",
                body: [
                    "If you are located in the European Economic Area (EEA), you have certain rights and protections under the General Data Protection Regulation (GDPR).",
                    "• Legal Basis for Processing: We process your data for purposes such as providing services, legitimate interests, or with your consent.",
                    "• Your Rights: You have the right to access, correct, or delete your personal information, and to withdraw consent at any time.",
                    "• Data Retention: We retain your data only as long as necessary for the purposes stated in this policy."
                ]
            },
            {
                heading: "5. Data Sharing and Disclosure",
                body: [
                    "We do not sell or rent your personal information to third parties. However, we may share data with trusted third parties for purposes such as:",
                    "• Service providers: We may share data with vendors who assist in website operation, analytics, or other services.",
                    "• Legal compliance: We may disclose your information if required by law or to protect the rights, safety, or property of Localbizs, our users, or others."
                ]
            },
            {
                heading: "6. Data Security",
                body: [
                    "We implement reasonable security measures to protect your data. However, no method of online transmission or storage is completely secure. While we strive to protect your information, we cannot guarantee its absolute security."
                ]
            },
            {
                heading: "7. Your Choices and Rights",
                body: [
                    "Depending on your location, you may have rights regarding your personal information, such as the right to access, correct, or delete your data. To exercise these rights, please contact us using the details provided below."
                ]
            },
            {
                heading: "8. Children’s Privacy",
                body: [
                    "Localbizs is not intended for children under 13 years of age. We do not knowingly collect information from children. If we learn we have collected personal data from a child, we will take steps to delete it."
                ]
            },
            {
                heading: "9. Third-Party Links",
                body: [
                    "Our website may contain links to other websites. This Privacy Policy applies solely to Localbizs. We encourage you to review the privacy policies of any external sites you visit."
                ]
            },
            {
                heading: "10. Updates to This Policy",
                body: [
                    "We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated “Last Updated” date."
                ]
            },
            {
                heading: "11. Contact Us",
                body: [
                    "If you have questions or concerns about this Privacy Policy, please contact us at:",
                    "Email: support@localbizs.com",
                    // "Address: [Your Business Address]"
                ]
            }
        ]
    };
};

const PrivacyPolicy = () => {
    const [policyData, setPolicyData] = useState({ title: "", content: [] });

    useEffect(() => {
        const getPolicyData = async () => {
            const data = await fetchPrivacyPolicy();
            setPolicyData(data);
        };
        getPolicyData();
    }, []);

    return (
        <div id="main-wrapper">
            <DarkHeader></DarkHeader>
            <section className="middle">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-11 col-lg-12 col-md-6 col-sm-12">
                            <div className="abt_caption">
                                <h2 className="ft-medium mb-4">{policyData.title}</h2>
                                {policyData.content.map((section, index) => (
                                    <div key={index}>
                                        <h3 className="ft-medium fs-md mb-3">{section.heading}</h3>
                                        {section.body.map((paragraph, i) => (
                                            <p className="mb-4" key={i}>{paragraph}</p>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default PrivacyPolicy;
import React from 'react';

// Sample location and hours data
const locationData = {
    address: "2919 N Flores St",
    cityStateZip: "San Antonio, TX 78212",
    neighborhood: "Alta Vista",
    mapSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.0148908503734!2d80.97350361499701!3d26.871267983145383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfd9a9f6d1727%3A0xb87eabf63f7e4cee!2sCafe%20Repertwahr!5e0!3m2!1sen!2sin!4v1649059491407!5m2!1sen!2sin",
    hours: [
        { day: "Mon", hours: "5:00 PM - 8:30 PM", status: "Open now" },
        { day: "Tue", hours: "5:00 PM - 8:30 PM", status: "" },
        { day: "Wed", hours: "5:00 PM - 8:30 PM", status: "" },
        { day: "Thu", hours: "5:00 PM - 8:30 PM", status: "" },
        { day: "Fri", hours: "5:00 PM - 6:30 PM", status: "" },
        { day: "Sat", hours: "Closed", status: "" },
        { day: "Sun", hours: "Closed", status: "" },
    ]
};

// LocationHours Component
const LocationHours = () => {
    return (
        <div className="d-block">
            <div className="jbd-01">
                <div className="jbd-details">
                    <h5 className="ft-bold fs-lg mb-3">Location &amp; Hours</h5>
                    <div className="Goodup-lot-wrap d-block">
                        <div className="row g-4">
                            <div className="col-xl-6 col-lg-6 col-md-12">
                                <div className="list-map-lot">
                                    <iframe
                                        src={locationData.mapSrc}
                                        width="100%"
                                        height="250"
                                        style={{ border: 0 }}
                                        allowFullScreen
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                </div>
                                <div className="list-map-capt">
                                    <div className="lio-pact"><span className="ft-medium text-info">{locationData.address}</span></div>
                                    <div className="lio-pact"><span className="hkio-oilp ft-bold">{locationData.cityStateZip}</span></div>
                                    <div className="lio-pact"><p className="ft-medium">{locationData.neighborhood}</p></div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12">
                                <table className="table table-borderless">
                                    <tbody>
                                        {locationData.hours.map((hour, index) => (
                                            <tr key={index}>
                                                <th scope="row">{hour.day}</th>
                                                <td>{hour.hours}</td>
                                                <td className={hour.status === "Open now" ? "text-success" : ""}>{hour.status}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocationHours;
// HomePage.js
import React from 'react';
import HomeBanner from './HomeBanner';
import PopularCategories from './PopularCategories';
import FeaturedSlider from './FeaturedSlider';
import FeaturedListings from './FeaturedListings';
import TabComponent from './TabComponent';
import NewsletterForm from './NewsletterForm';
import Header from './Header';

const HomePage = () => {
  const title = "Discover the Perfect Place in Your City";
  const subtitle = "Your guide to finding top-rated local services and businesses. Whether you're looking for nail salons, plumbers, or takeout, we have you covered!";
  const placeholder = "Nail salons, plumbers, takeout...";

  const categories = [
    { link: "half-map-search-3.html", icon: "fa-stethoscope" },
    { link: "half-map-search-3.html", icon: "fa-shopping-basket" },
    { link: "half-map-search-3.html", icon: "fa-utensils" },
    { link: "half-map-search-3.html", icon: "fa-car-alt" },
    { link: "half-map-search-3.html", icon: "fa-book-open" },
  ];

  const clientCounts = [
    { number: 35, color: 'success', label: 'Happy Clients' },
    { number: 35, color: 'warning', label: 'Happy Clients' },
    { number: 35, color: 'info', label: 'Happy Clients' },
  ];

  return (
    <div>
      <Header></Header>
      <HomeBanner
        title={title}
        subtitle={subtitle}
        placeholder={placeholder}
        categories={categories}
        clientCounts={clientCounts}
      />
      <PopularCategories></PopularCategories>

      {/* <FeaturedSlider></FeaturedSlider> */}
      {/* <FeaturedListings></FeaturedListings> */}
      <NewsletterForm></NewsletterForm>
      {/* <TabComponent></TabComponent> */}
      {/* <footer></footer> */}
    </div>
  );
};

export default HomePage;

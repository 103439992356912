import React from 'react';
import { Link } from 'react-router-dom';

// Define your navigation items
const navItems = [
    {
        title: "Home",
        href: "/home",
        // subItems: [
        //     { title: "Home Screen 1", href: "index.html" }
        //     // { title: "Home Screen 2", href: "home-2.html" },
        //     // { title: "Home Screen 3", href: "home-3.html" },
        //     // { title: "Home Screen 4", href: "home-4.html" },
        //     // { title: "Home Screen 5", href: "home-5.html" },
        // ],
    },
    {
        title: "Search Business",
        href: "/home",
      
    },
    {
        title: "About Us",
        href: "/about-us",
       
    },
    {
        title: "Contact Us",
        href: "contact-us",
     
    },
    {
        title: "Privacy Policy",
        href: "privacy-policy",
     
    },
];

const DarkHeader = () => {
    return (
        <div className="header header-light dark-text">
            <div className="container">
                <nav id="navigation" className="navigation navigation-landscape">
                    <div className="nav-header">
                        <a className="nav-brand" href="#">
                            <img src="/assets/img/logo.png" className="logo" alt="" />
                        </a>
                        <div className="nav-toggle"></div>
                        <div className="mobile_nav">
                            <ul>
                                <li>
                                    <a href="#" data-bs-toggle="modal" data-bs-target="#login" className="theme-cl fs-lg">
                                        <i className="lni lni-user"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="add-listing.html" className="crs_yuo12 w-auto text-white theme-bg">
                                        <span className="embos_45"><i className="fas fa-plus me-2"></i>Add Listing</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="nav-menus-wrapper" style={{ transitionProperty: "none" }}>
                        <span className="nav-menus-wrapper-close-button">✕</span>
                        <ul className="nav-menu">
                            {navItems.map((item, index) => (
                                <li key={index} className={item.subItems ? "" : "active"}>
                                    <a href={item.href}>
                                        {item.title}
                                        {item.subItems && <span className="submenu-indicator"><span className="submenu-indicator-chevron"></span></span>}
                                    </a>
                                    {item.subItems && (
                                        <ul className="nav-dropdown nav-submenu" style={{ right: "auto", display: "none" }}>
                                            {item.subItems.map((subItem, subIndex) => (
                                                <li key={subIndex}>
                                                    <a href={subItem.href || "javascript:void(0);"}>
                                                        {subItem.title}
                                                        {subItem.subItems && <span className="submenu-indicator"><span className="submenu-indicator-chevron"></span></span>}
                                                    </a>
                                                    {subItem.subItems && (
                                                        <ul className="nav-dropdown nav-submenu">
                                                            {subItem.subItems.map((subSubItem, subSubIndex) => (
                                                                <li key={subSubIndex}>
                                                                    <a href={subSubItem.href}>{subSubItem.title}</a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </ul> */}
 <div className="nav-menus-wrapper" style={{ transitionProperty: 'none' }}>
                        <ul className="nav-menu">
                            <li><Link to="/home">Home</Link></li> {/* Updated to use Link */}
                            <li><Link to="/home">Search Business</Link></li> {/* Updated to use Link */}
                            <li><Link to="/about-us">About Us</Link></li>
                            <li><Link to="/contact-us">Contact</Link></li>
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>

                        </ul>
                        <ul className="nav-menu nav-menu-social align-to-right">
                            <li>
                                <a href="#" data-bs-toggle="modal" data-bs-target="#login" className="ft-bold">
                                    <i className="fas fa-sign-in-alt me-1 theme-cl"></i>Sign In
                                </a>
                            </li>
                            <li className="add-listing">
                                <a href="add-listing.html">
                                    <i className="fas fa-plus me-2"></i>Add Listing
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default DarkHeader;
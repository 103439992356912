import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import { Helmet } from 'react-helmet';

const Banner = () => {
    const [defaultLocation, setDefaultLocation] = useState('');
    const navigate = useNavigate(); // Initialize navigate for redirect

    useEffect(() => {
        const setDefaultLocationSearch = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(async (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;

                    // Fetch initial results based on geolocation, if desired.
                    try {
                        const response = await fetch(`/api/search?location=${latitude},${longitude}&pageNumber=1&pageSize=10`);
                        const results = await response.json();
                        // You can optionally handle the results here if you want
                    } catch (error) {
                        console.error("Error fetching default location search:", error);
                    }
                }, async () => {
                    await fetchLocationByIP();
                });
            } else {
                fetchLocationByIP();
            }
        };

        const fetchLocationByIP = async () => {
            try {
                const response = await fetch('https://ipinfo.io/json?token=8b6dd2f461e0d9');
                const data = await response.json();
                setDefaultLocation(data.city);
            } catch (error) {
                console.error('Error fetching user location by IP:', error);
            }
        };

        setDefaultLocationSearch();
    }, []);

    const handleSearch = (e) => {
        e.preventDefault();

        const query = document.querySelector('input[placeholder="Nail salons, plumbers, takeout..."]').value || "";
        const location = document.querySelector('input[placeholder="San Francisco, CA"]').value || "";
        const category = document.querySelector('select').value || "";

        if (!query && !location && !category) {
            alert("Please provide a search term, location, or category.");
            return;
        }

        // Redirect to BusinessListing.js with query parameters
        navigate(`/businesslisting?query=${encodeURIComponent(query)}&location=${encodeURIComponent(location)}&category=${encodeURIComponent(category)}&pageNumber=1&pageSize=10`);
    };

    return (
        <>

        <Helmet>
        <title>Find Great Places to Eat, Shop, and Relax | YourCity Business Directory</title>
        <meta name="description" content="Explore nail salons, restaurants, plumbers, or other businesses in your area. Find the best businesses and services near you." />
        <meta name="keywords" content="business directory, local businesses, nail salons, restaurants, plumbers, shopping, local services" />
        <link rel="canonical" href="https://yourdomain.com" />
        <meta property="og:title" content="Explore the Best Local Businesses in Your City" />
        <meta property="og:description" content="Find nail salons, restaurants, and other services near you. Browse our directory for the best businesses." />
        <meta property="og:url" content="https://yourdomain.com" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/assets/img/banner-image.png" />
    </Helmet>
        <div className="home-banner margin-bottom-0" style={{ background: '#f41b3b url(./assets/img/banner-image.png) no-repeat' }} data-overlay="4">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="banner_caption text-center mb-5">
                            <h1 className="banner_title ft-bold mb-1">Great Workplace for Your Business</h1>
                            <p className="fs-md ft-medium">Explore wonderful places to stay, salon, shopping, massage or visit local areas.</p>
                        </div>

                        <form className="main-search-wrap fl-wrap" onSubmit={handleSearch}>
                            <div className="main-search-item">
                                <input type="text" className="form-control radius" placeholder="Nail salons, plumbers, takeout..." />
                            </div>
                            <div className="main-search-item">
                                <input type="text" className="form-control" placeholder="San Francisco, CA" defaultValue={defaultLocation} />
                            </div>
                            <div className="main-search-item">
                                <select className="form-control">
                                    <option value="">Choose Categories</option>
                                    <option value="1">Restaurants</option>
                                    <option value="2">Information Technology</option>
                                    <option value="3">Cloud Computing</option>
                                    <option value="4">Engineering Services</option>
                                    <option value="5">Healthcare/Pharma</option>
                                    <option value="6">Telecom/ Internet</option>
                                    <option value="7">Finance/Insurance</option>
                                </select>
                            </div>
                            <div className="main-search-button">
                                <button className="btn full-width theme-bg text-white" type="submit">Search<i className="fas fa-search"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Banner;
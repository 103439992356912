import React from 'react';

// Sample contact information data
const contactInfo = [
    {
        icon: "fas fa-globe",
        title: "Live Site",
        detail: "https://www.localbizs.com/"
    },
    {
        icon: "fas fa-envelope",
        title: "Drop a Mail",
        detail: "support@localbizs.com"
    },
    {
        icon: "fas fa-phone",
        title: "Call Us",
        detail: "(210) 659 584 756"
    },
    {
        icon: "fas fa-map-marker-alt",
        title: "Get Directions",
        detail: "2919 N Flores St San Antonio, TX 78212"
    }
];

// ContactInfo Component
const ContactInfo = () => {
    return (
        <div className="jb-apply-form bg-white rounded py-4 px-4 border mb-4">
            <div className="uli-list-info">
                <ul>
                    {contactInfo.map((item, index) => (
                        <li key={index}>
                            <div className="list-uiyt">
                                <div className="list-iobk"><i className={item.icon}></i></div>
                                <div className="list-uiyt-capt">
                                    <h5>{item.title}</h5>
                                    <p>{item.detail}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ContactInfo;
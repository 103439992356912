// FeaturedSlider.js
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick'; // Ensure slick-carousel is installed
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// const FeaturedSlider = () => {
  // State to hold slider data
//   const [sliderData, setSliderData] = useState([]);

//   // Fetch or define dynamic data (can be replaced with API fetch)
//   useEffect(() => {
//     const fetchSliderData = async () => {
//       // Simulate API fetch
//     //   const data = [
//     //     { url: 'https://via.placeholder.com/1200x900', alt: 'Image 1' },
//     //     { url: 'https://via.placeholder.com/1200x900', alt: 'Image 2' },
//     //     { url: 'https://via.placeholder.com/1200x900', alt: 'Image 3' },
//     //     { url: 'https://via.placeholder.com/1200x900', alt: 'Image 4' },
//     //   ];
//     //   setSliderData(data);
//     // };

//     fetchSliderData();
//   }, []);

  // Slick slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
  };
  const FeaturedSlider = ({ slider }) => {
  return (
    <div className="featured-slick mb-4">
      <Slider {...settings}>
        {slider.sliderData.map((item, index) => (
          <div key={index} className="dlf-flew auto slick-slide">
            <a href={item.url} className="mfp-gallery">
              <img src={item.url} alt={item.alt} className="img-fluid mx-auto" />
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default FeaturedSlider;
// AboutBusiness.js
import React, { useEffect, useState } from 'react';

// const AboutBusiness = ({ businessId }) => {
//   const [aboutContent, setAboutContent] = useState('');

//   // Fetch business details dynamically
//   useEffect(() => {
//     const fetchBusinessDetails = async () => {
//       try {
//         // Replace with your API endpoint or logic
//         const response = await fetch(
//           `http://localhost:5294/api/BestBusiness/${businessId}`
//         );
//         const data = await response.json();

//         // Assuming 'data.about' contains the about information
//         setAboutContent(data.about);
//       } catch (error) {
//         console.error('Error fetching business details:', error);
//       }
//     };

//     if (businessId) fetchBusinessDetails();
//   }, [businessId]);
  const AboutBusiness = ({ description }) => {

  return (
    <div className="d-block">
      <div className="jbd-01">
        <div className="jbd-details">
          <h5 className="ft-bold fs-lg">About the Business</h5>
          <div className="d-block mt-3">
            <p>{description.aboutContent}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBusiness;
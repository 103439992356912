// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <div className="header header-transparent change-logo">
            <div className="container">
                <nav id="navigation" className="navigation navigation-landscape">
                    <div className="nav-header">
                        <a className="nav-brand static-logo" href="#">
                            <img src="assets/img/logo-light.png" className="logo" alt="" />
                        </a>
                        <a className="nav-brand fixed-logo" href="#">
                            <img src="assets/img/logo.png" className="logo" alt="" />
                        </a>
                        <div className="nav-toggle"></div>
                        <div className="mobile_nav">
                            <ul>
                                <li>
                                    <a href="#" data-bs-toggle="modal" data-bs-target="#login" className="theme-cl fs-lg">
                                        <i className="lni lni-user"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="/add-listing" className="crs_yuo12 w-auto text-white theme-bg">
                                        <span className="embos_45"><i className="fas fa-plus me-2"></i>Add Listing</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="nav-menus-wrapper" style={{ transitionProperty: 'none' }}>
                        <ul className="nav-menu">
                            <li><Link to="/home">Home</Link></li> {/* Updated to use Link */}
                            <li><Link to="/home">Search Business</Link></li> {/* Updated to use Link */}
                            <li><Link to="/about-us">About Us</Link></li>
                            <li><Link to="/contact-us">Contact</Link></li>
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>

                        </ul>
                        <ul className="nav-menu nav-menu-social align-to-right">
                            <li>
                                <a href="#" data-bs-toggle="modal" data-bs-target="#login" className="ft-bold">
                                    <i className="fas fa-sign-in-alt me-1 theme-cl"></i>Sign In
                                </a>
                            </li>
                            <li className="add-listing">
                                <Link to="/add-listing">
                                    <i className="fas fa-plus me-2"></i>Add Listing
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
        
    );
};

export default Header;
import React, { useState } from 'react';

const AppointmentForm = ({ initialDate = '', onSubmit }) => {
    const [formData, setFormData] = useState({
        date: initialDate || new Date().toISOString().split('T')[0],
        name: '',
        email: '',
        phone: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (onSubmit) {
            onSubmit(formData);
        }
    };

    return (
        <div className="jb-apply-form bg-white rounded py-4 px-4 border mb-4">
            <h4 className="ft-bold mb-1">Make An Appointment</h4>

            <div className="Goodup-boo-space mt-3">
                <form onSubmit={handleSubmit} className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group mb-3">
                            <label className="ft-medium small mb-1">Select Date</label>
                            <div className="cld-box">
                                <i className="ti-calendar"></i>
                                <input
                                    type="date"
                                    name="date"
                                    className="form-control"
                                    value={formData.date}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group mb-3">
                            <label className="ft-medium small mb-1">Name</label>
                            <input
                                type="text"
                                name="name"
                                className="border form-control rounded ps-3"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Enter your name"
                            />
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group mb-3">
                            <label className="ft-medium small mb-1">Email</label>
                            <input
                                type="email"
                                name="email"
                                className="border form-control rounded ps-3"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter your email"
                            />
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group mb-3">
                            <label className="ft-medium small mb-1">Phone</label>
                            <input
                                type="tel"
                                name="phone"
                                className="border form-control rounded ps-3"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder="Enter your phone number"
                            />
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <button
                            type="submit"
                            className="btn text-light rounded full-width theme-bg ft-medium">
                            Make Appointment
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AppointmentForm;
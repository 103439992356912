import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Static mapping for icons, links, and default text
const iconMap = {
    "Best Doctors": { icon: "fas fa-stethoscope", link: "half-map-search-2.html" },
    "Best Education": { icon: "fas fa-book-open", link: "half-map-search-2.html" },
    "Best Entertainment": { icon: "fas fa-building", link: "half-map-search-2.html" },
    "Best Food": { icon: "fas fa-utensils", link: "half-map-search-2.html" },
    "Best Health": { icon: "fas fa-heartbeat", link: "half-map-search-2.html" },
    "Best Home Services": { icon: "fas fa-screwdriver", link: "half-map-search-2.html" },
    "Best Lawyers": { icon: "fas fa-gavel", link: "half-map-search-2.html" },
    "Best Local Businesses": { icon: "fas fa-store", link: "half-map-search-2.html" },
    "Best Local Services": { icon: "fas fa-hands-helping", link: "half-map-search-2.html" },
    "Best Professional Services": { icon: "fas fa-briefcase", link: "half-map-search-2.html" },
    "Best Restaurants": { icon: "fas fa-utensils", link: "half-map-search-2.html" },
    "Best Shopping": { icon: "fas fa-shopping-basket", link: "half-map-search-2.html" },
    "Best Solicitors": { icon: "fas fa-balance-scale", link: "half-map-search-2.html" },
    "Best Speciality Cuisine": { icon: "fas fa-wine-glass", link: "half-map-search-2.html" },
    "Best Travel and Spa": { icon: "fas fa-plane", link: "half-map-search-2.html" }
};

const PopularCategories = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
              const response = await axios.get('https://api.localbizs.com/api/categories/grouped');
            // const response = await axios.get('http://localhost:5294/api/categories/grouped');

                const categoriesData = response.data?.$values || [];
                setCategories(categoriesData);
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    if (loading) return <div>Loading...</div>;

    return (
        <section className="space min gray">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="sec_title position-relative text-center mb-5">
                            <h6 className="mb-0 theme-cl">Popular Categories</h6>
                            <h2 className="ft-bold">Browse Top Categories</h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {categories.map((category, index) => {
                        const { categoryName, businessCount, cityCount } = category;
                        const { icon, link } = iconMap[categoryName] || {};

                        return (
                            <div key={index} className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
                                <div className="cats-wrap text-center">
                                    <a href={link} className="Goodup-catg-wrap">
                                        <div className="Goodup-catg-city">{cityCount} Cities</div>
                                        <div className="Goodup-catg-icon">
                                            <i className={icon}></i>
                                        </div>
                                        <div className="Goodup-catg-caption">
                                            <h4 className="fs-md mb-0 ft-medium">{categoryName}</h4>
                                            <span className="text-muted">{businessCount} Listings</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default React.memo(PopularCategories);
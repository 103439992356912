// CookieBanner.js
import React, { useState, useEffect } from 'react';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Check if the user has already given consent
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted === null) {
      setShowBanner(true); // Show banner if not previously accepted/rejected
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowBanner(false); // Hide the banner
  };

  const handleRejectCookies = () => {
    localStorage.setItem('cookiesAccepted', 'false');
    setShowBanner(false); // Hide the banner
  };

  if (!showBanner) return null; // Do not render the banner if the user has already interacted

  return (
    <div style={styles.banner}>
      <p>
        We use cookies to improve your experience. By using our website, you agree to our use of cookies. 
        <a href="/privacy-policy" style={styles.link}>Learn more</a>.
      </p>
      <button onClick={handleAcceptCookies} style={styles.button}>Accept</button>
      <button onClick={handleRejectCookies} style={styles.button}>Reject</button>
    </div>
  );
};

const styles = {
  banner: {
    position: 'fixed',
    bottom: '0',
    left: '0',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    padding: '15px',
    textAlign: 'center',
    fontSize: '16px',
    zIndex: '1000',
  },
  button: {
    backgroundColor: '#ffcc00',
    border: 'none',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    marginLeft: '10px',
  },
  link: {
    color: '#ffcc00',
    textDecoration: 'none',
  }
};

export default CookieBanner;
import React from 'react';

const SearchBarBanner = ({ dummyBannerData = {} }) => {
    // const {
    //     imageSrc = "assets/img/placeholder.png", // Fallback image
    //     title = "No Title Available",
    //     reviews = 0,
    //     rating = 0,
    //     priceRange = 1,
    //     claimed = false,
    //     categories = [],
    //     status = "Closed",
    //     hours = "N/A"
    // } = dummyBannerData;

    const renderStars = (rating) => {
        return [...Array(5)].map((_, i) => (
            <i key={i} className={`fas fa-star ${i < rating ? 'active' : ''}`}></i>
        ));
    };

    const renderPriceRange = (range) => {
        return [...Array(range)].map((_, i) => (
            <span key={i} className="active"><i className="fas fa-dollar-sign"></i></span>
        ));
    };

    return (
        <section className="featured-wraps gray">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="Goodup-ops-bhriol">
                            <div className="Goodup-lkp-flex d-flex align-items-start justify-content-start">
                                <div className="Goodup-lkp-thumb">
                                    <img src={dummyBannerData.imageSrc} className="img-fluid" width="90" alt={dummyBannerData.title} />
                                </div>
                                <div className="Goodup-lkp-caption ps-3">
                                    <div className="Goodup-lkp-title">
                                        <h1 className="mb-0 ft-bold">{dummyBannerData.title}</h1>
                                    </div>
                                    <div className="Goodup-ft-first">
                                        <div className="Goodup-rating">
                                            <div className="Goodup-rates">
                                                {renderStars(dummyBannerData.rating)}
                                            </div>
                                        </div>
                                        <div className="Goodup-price-range">
                                            <span className="ft-medium">{dummyBannerData.reviews} Reviews</span>
                                            <div className="d-inline ms-2">
                                                {renderPriceRange(dummyBannerData.priceRange)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-block mt-3">
                                        <div className="list-lioe">
                                            <div className="list-lioe-single">
                                                <span className={`ft-medium text-${dummyBannerData.claimed ? 'info' : 'secondary'}`}>
                                                    <i className="fas fa-check-circle me-1"></i>
                                                    {dummyBannerData.claimed ? 'Claimed' : 'Unclaimed'}
                                                </span>
                                            </div>
                                            <div className="list-lioe-single ms-2 ps-3 seperate">
                                                {dummyBannerData.categories.map((category, index) => (
                                                    <a 
                                                        key={index} 
                                                        href="javascript:void(0);" 
                                                        className="text-dark ft-medium ms-1">
                                                        {category}
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-block mt-1">
                                        <div className="list-lioe">
                                            <div className="list-lioe-single">
                                                <span className={`ft-medium text-${dummyBannerData.status === 'Closed' ? 'danger' : 'success'}`}>
                                                    {dummyBannerData.status}
                                                </span>
                                                <span className="ft-medium ms-2">{dummyBannerData.hours}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SearchBarBanner;
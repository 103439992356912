import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const locations = [
  'birmingham', 'new york', 'los angeles', 'chicago', 'miami', 'houston',
  'san francisco', 'dallas', 'phoenix', 'philadelphia', 'seattle', 'atlanta',
  'san diego', 'orlando', 'london', 'paris', 'tokyo', 'sydney', 'dubai',
  'berlin', 'toronto', 'singapore', 'mumbai', 'beijing', 'chennai',
  'amsterdam', 'madrid', 'barcelona', 'rome', 'lisbon', 'moscow',
  'stockholm', 'copenhagen', 'oslo', 'helsinki', 'dublin', 'brussels',
  'cairo', 'johannesburg', 'nairobi', 'accra', 'lagos', 'cape town',
  'santiago', 'bogota', 'lima', 'caracas', 'quito', 'asuncion',
  'kuala lumpur', 'jakarta', 'manila', 'bangkok', 'seoul', 'ho chi minh city',
  'hanoi', 'taipei', 'new delhi', 'kolkata', 'ahmedabad', 'pune',
  'canberra', 'wellington', 'auckland', 'christchurch', 'melbourne',
  'brisbane', 'perth', 'abu dhabi', 'doha', 'kuwait city', 'muscat',
  'copenhagen', 'stockholm', 'reykjavik', 'vilnius', 'tbilisi', 'batumi',
  'algiers', 'addis ababa', 'maputo', 'dar es salaam', 'tunis',
  'monrovia', 'freetown', 'harare', 'kinshasa', 'luanda', 'antananarivo',
  'port-au-prince', 'kingston', 'bridgetown', 'georgetown', 'st. john\'s',
  'nassau', 'castries', 'scarborough', 'tortola', 'st. thomas', 'st. croix',
  'santo domingo', 'puerto plata', 'santiago de los caballeros', 'punta cana',
  'curitiba', 'porto alegre', 'salvador', 'belo horizonte', 'recife',
  'fortaleza', 'manaus', 'florianopolis', 'goiania', 'campinas',
  'vancouver', 'calgary', 'edmonton', 'winnipeg', 'halifax', 'ottawa',
  'quebec city', 'st. john\'s', 'st. catharines', 'hamilton', 'windsor',
  'chattanooga', 'salt lake city', 'albuquerque', 'tucson', 'omaha',
  'kansas city', 'columbus', 'memphis', 'louisville', 'tampa', 'jacksonville',
  'madison', 'green bay', 'lansing', 'anchorage', 'honolulu'
];
const categoriestest = [
  {
    name: 'Food',
    favicon: '<link rel="icon" href="path/to/food-icon.png" type="image/png" />'
  },
  {
    name: 'Health',
    favicon: '<link rel="icon" href="path/to/health-icon.png" type="image/png" />'
  },
  {
    name: 'Retail',
    favicon: '<link rel="icon" href="path/to/retail-icon.png" type="image/png" />'
  },
  {
    name: 'Spa',
    favicon: '<link rel="icon" href="/assets/img/icons/spa.png.png" type="image/png" />'
  },
  {
    name: 'Beauty',
    favicon: '<link rel="icon" href="/assets/img/icons/beauty-icon.png" type="image/png" />'
  },
  {
    name: 'Home Services',
    favicon: '<link rel="icon" href="/assets/img/icons/home-services-icon.png" type="image/png" />'
  },
  {
    name: 'Automotive',
    favicon: '<link rel="icon" href="/assets/img/icons/automotive-icon.png" type="image/png" />'
  }
];
const categories = [
  {
      name: '',
      iconClass: 'fas fa-stethoscope',
      link: 'half-map-search-3.html',
      bgClass: 'bg-1' // You can set a default class or specific class
  },
  {
      name: '',
      iconClass: 'fas fa-shopping-basket',
      link: 'half-map-search-3.html',
      bgClass: 'bg-2'
  },
  {
      name: '',
      iconClass: 'fas fa-utensils',
      link: 'half-map-search-3.html',
      bgClass: 'bg-3'
  },
  {
      name: '',
      iconClass: 'fas fa-car-alt',
      link: 'half-map-search-3.html',
      bgClass: 'bg-4'
  },
  {
      name: '',
      iconClass: 'fas fa-book-open',
      link: 'half-map-search-3.html',
      bgClass: 'bg-5'
  },
  {
      name: '',
      iconClass: 'fas fa-ellipsis-h',
      link: 'half-map-search-3.html',
      bgClass: 'bg-more'
  }
];
const highlightMatch = (text, query) => {
  const parts = text.split(new RegExp(`(${query})`, 'gi'));
  return parts.map((part, index) =>
    part.toLowerCase() === query.toLowerCase() ? (
      <span key={index} className="highlight">{part}</span>
    ) : (
      part
    )
  );
};
const HomeBanner = ({ title, subtitle, placeholder, clientCounts }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [currentLocation, setCurrentLocation] = useState('default-location');
  const navigate = useNavigate();

  // Detect user's location with improved matching logic
  const detectLocation = async () => {
    try {
      const response = await axios.get(`https://ipinfo.io/json?token=8b6dd2f461e0d9`);
      const locationData = response.data.city || 'unknown';

      console.log(`Detected location: ${locationData}`); // Debugging step

      const matchedLocation = locations.find(loc =>
        locationData.toLowerCase().includes(loc.toLowerCase())
      );

      setCurrentLocation(matchedLocation || 'san francisco');
    } catch (error) {
      console.error('Error detecting location:', error);
      setCurrentLocation('san francisco');
    }
  };

  // Fetch autocomplete suggestions
  const fetchSuggestions = async (query) => {
    try {
     const response = await axios.get(`https://api.localbizs.com/api/Search/autocomplete?query=${query}`);
    //    const response = await axios.get(`http://localhost:5294/api/Search/autocomplete?query=${query}`);
      const data = response.data?.$values || [];
      setSuggestions(data);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length > 2) {
      fetchSuggestions(query);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion);
    setShowSuggestions(false);
  };

  // Handle search
  const handleSearch = () => {
    if (searchQuery.trim() !== '') {
      // Convert the search query to lowercase and replace spaces with hyphens
      const formattedQuery = searchQuery.toLowerCase().replace(/\s+/g, '-');

      // Check if the search query includes a known location from the list
      const matchingLocation = locations.find((loc) =>
        searchQuery.toLowerCase().includes(loc)
      );

      // Use the matched location or fallback to the detected location
      const locationForURL = matchingLocation || currentLocation;

      // Remove the location from the search query
      const cleanQuery = matchingLocation
        ? formattedQuery.replace(`-${matchingLocation}`, '')
        : formattedQuery;

      // Log for debugging
      console.log(`Navigating to: /search/${locationForURL}/${cleanQuery}`); // Debugging step

      navigate(`/bizs/${locationForURL}/${cleanQuery}`);
    }
  };

  useEffect(() => {
    detectLocation();
  }, []);

  return (
    <div className="home-banner margin-bottom-0 gray">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="pe-3">
              <div className="banner_caption text-left mb-4">
                <h1 className="banner_title ft-normal mb-1">
                  <span className="theme-cl ft-bold">Find Now</span><br />{title}
                </h1>
                <p className="fs-md ft-regular">{subtitle}</p>
              </div>

              <form className="main-search-wrap fl-wrap one-column" onSubmit={(e) => e.preventDefault()}>
                <div className="main-search-item">
                  <span className="search-tag">Find</span>
                  <input
                    type="text"
                    className="form-control radius"
                    placeholder={placeholder}
                    value={searchQuery}
                    onChange={handleChange}
                    onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                    onFocus={() => searchQuery.length > 2 && setShowSuggestions(true)}
                  />

                  {showSuggestions && suggestions.length > 0 && (
                    <ul className="autocomplete-suggestions">
                      {suggestions.map((suggestion, index) => (
                        <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                          <i className="fas fa-search"></i> {' '}
                          {highlightMatch(suggestion, searchQuery)}

                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div className="main-search-button">
                  <button
                    className="btn full-width theme-bg text-white"
                    type="button"
                    onClick={handleSearch}
                  >
                    Search <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>

              {/* <div className="Goodup-over-cats-sty">
                                <h6 className="ft-bold mb-1">Browse categories</h6>
                                <ul>
                                    {categories.map((category, index) => (
                                        <li key={index}>
                                            <a href={`#${category}`} className={`bg-${index + 1}`}>
                                                <i className="fas fa-tag"></i> {category}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div> */}
             <div className="Goodup-over-cats-sty">
    <h6 className="ft-bold mb-1">Browse categories</h6>
    <span className="small">Get Popular Categories in Your City</span>
    <ul>
        {categories.map((category, index) => (
            <li key={index}>
                <a href={category.link} className={category.bgClass}>
                    <i className={category.iconClass}></i> {/* Keeping Font Awesome icon */}
                    <span>{category.name}</span> {/* Optional: Display category name */}
                </a>
            </li>
        ))}
        <li>
            <a href="half-map-search-3.html" className="bg-more">+45</a>
        </li>
    </ul>
</div>
            </div>
          </div>

          <div className="col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 order-lg-3">
            <div className="Goodup-counter">
              <ul>
                {clientCounts.map((count, index) => (
                  <li key={index}>
                    <div className="Goodup-ylp">
                      <div className="Goodup-ylp-first">
                        <h3><span className={`text-${count.color} count`}>{count.number}</span>K</h3>
                      </div>
                      <div className="Goodup-ylp-last">
                        <span>{count.label}</span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
            <div className="list-tyuhn">
              <img src="assets/img/list.png" className="img-fluid" alt="Listing" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
import React, { useState } from 'react';

// FAQ data for Doctors
const doctorFaqs = [
  {
    question: "Can I list my medical practice on LocalBizs.com for free?",
    answer: "Yes, you can list your medical practice on LocalBizs.com for free. Simply create an account and follow the steps to set up your profile. You can provide your clinic information, specialties, office hours, and contact details to make it easier for patients to find you.",
  },
  {
    question: "How can I update my medical qualifications on LocalBizs.com?",
    answer: "To update your medical qualifications, log into your LocalBizs.com account, go to your profile settings, and update the 'Qualifications' section. Make sure to include any recent certifications, degrees, or specialties you have acquired, to keep your profile up to date.",
  },
  {
    question: "What features can I use to manage patient reviews and ratings?",
    answer: "LocalBizs.com offers a feature to manage patient reviews and ratings on your profile. You can respond to patient reviews directly, address concerns, and even ask patients to leave feedback after their visit. Maintaining a positive rating helps improve visibility and trust with potential patients.",
  },
  {
    question: "How do I manage my schedule and patient appointments?",
    answer: "You can integrate your calendar with LocalBizs.com to easily manage your appointments. The platform allows you to set office hours, accept online bookings, and even send appointment reminders to patients. You can also manage cancellations and reschedule appointments directly from your profile.",
  },
  {
    question: "How do I ensure my clinic's information is accurate on LocalBizs.com?",
    answer: "To ensure your clinic's information is always accurate, regularly update your LocalBizs.com profile with any changes in your contact information, office hours, or medical services. You can also verify your clinic's address and add special instructions for patients who visit your practice.",
  },
  {
    question: "Can I add my medical services and specialties to my LocalBizs.com listing?",
    answer: "Yes, you can add all your medical services and specialties to your LocalBizs.com profile. Whether you specialize in general medicine, dermatology, cardiology, or any other field, you can specify your areas of expertise to attract the right patients to your practice.",
  },
  {
    question: "How do I ensure patient confidentiality and privacy through LocalBizs.com?",
    answer: "LocalBizs.com is committed to patient privacy and complies with all relevant medical privacy laws, including HIPAA. Your patient communications and medical information should be handled through secure systems. Always ensure that you do not share sensitive medical data publicly on your profile.",
  },
  {
    question: "Can I connect with other doctors or medical professionals on LocalBizs.com?",
    answer: "Yes, LocalBizs.com offers features to network with other medical professionals in your field. You can join discussion groups, share insights, and collaborate with colleagues to expand your knowledge and improve patient care.",
  },
];

// FAQ data for Restaurants
const restaurantFaqs = [
  {
    question: "How do I create a restaurant listing on LocalBizs.com?",
    answer: "Creating a restaurant listing on LocalBizs.com is simple. Sign up for an account, complete your restaurant profile, and add details like your menu, location, opening hours, and contact info.",
  },
  {
    question: "Can I offer delivery and takeout through LocalBizs.com?",
    answer: "Yes, LocalBizs.com allows you to list delivery and takeout options directly on your restaurant's profile. This helps attract more customers who prefer to order from home or pick up food.",
  },
  {
    question: "How can I update my restaurant's menu on LocalBizs.com?",
    answer: "To update your menu, log into your LocalBizs.com account, go to your restaurant profile settings, and edit the 'Menu' section. You can add, remove, or modify items as needed.",
  },
  {
    question: "Can I add my restaurant's special offers to my LocalBizs.com profile?",
    answer: "Yes, you can add special offers, promotions, and discounts to your restaurant profile. This helps attract customers looking for deals and can increase your restaurant's visibility.",
  },
  {
    question: "How do I manage my restaurant’s reviews and ratings on LocalBizs.com?",
    answer: "You can manage reviews and ratings by responding to customer feedback, addressing complaints, and thanking customers for positive reviews. Consistently managing your reviews helps build trust and a good reputation.",
  },
  {
    question: "Can I list my restaurant's reservation availability on LocalBizs.com?",
    answer: "Yes, LocalBizs.com offers features to manage reservations. You can enable online booking for your customers, display available slots, and send reservation confirmations to enhance customer convenience.",
  },
  {
    question: "How do I ensure my restaurant's information is accurate on LocalBizs.com?",
    answer: "To ensure your restaurant's information is always accurate, regularly update your LocalBizs.com profile with any changes to your hours, location, or menu. You can also add special instructions or event details.",
  },
  {
    question: "Can I add photos of my restaurant and dishes to my profile?",
    answer: "Yes, you can upload photos of your restaurant’s interior, dishes, and ambiance to showcase your restaurant on your LocalBizs.com profile. High-quality images attract more customers and give them a better idea of what to expect.",
  },
];
const lawyerFaqs = [
  {
    question: "Can I list my law firm on LocalBizs.com for free?",
    answer: "Yes, you can list your law firm on LocalBizs.com for free. Simply create an account and follow the steps to set up your profile. You can include details such as your practice areas, office hours, contact information, and client reviews.",
  },
  {
    question: "How do I update my legal qualifications and certifications?",
    answer: "To update your qualifications, log into your LocalBizs.com account, go to your profile settings, and update the 'Qualifications' section. Make sure to include your law school graduation, certifications, bar memberships, and any specialized legal training.",
  },
  {
    question: "Can I manage client reviews and ratings on my LocalBizs.com profile?",
    answer: "Yes, LocalBizs.com allows you to manage client reviews. You can respond to reviews, address concerns, and encourage clients to leave feedback after their consultations or cases. Positive reviews can enhance your credibility and visibility.",
  },
  {
    question: "How do I manage my law firm’s schedule and client appointments?",
    answer: "LocalBizs.com integrates with your calendar, making it easy to manage consultations, court dates, and meetings. You can set your availability, accept online appointments, and send reminders to clients for upcoming meetings or hearings.",
  },
  {
    question: "How do I ensure my law firm’s information is accurate on LocalBizs.com?",
    answer: "Regularly update your profile to ensure all your contact information, office hours, and practice areas are current. You can also verify your law firm’s address and add special instructions for clients visiting your office.",
  },
  {
    question: "Can I list my areas of legal expertise on LocalBizs.com?",
    answer: "Yes, you can list all your practice areas, including personal injury, criminal defense, corporate law, family law, immigration law, and more. Detailing your areas of expertise helps potential clients find you based on their legal needs.",
  },
  {
    question: "How do I ensure confidentiality and privacy for my clients through LocalBizs.com?",
    answer: "LocalBizs.com complies with all privacy laws, including those related to client confidentiality. Make sure to communicate securely with clients and avoid sharing sensitive information publicly on your profile.",
  },
  {
    question: "Can I network with other legal professionals on LocalBizs.com?",
    answer: "Yes, LocalBizs.com provides features to connect with other lawyers and legal professionals. You can join legal groups, participate in discussions, share insights, and collaborate on cases or professional development.",
  },
];
const entertainmentFaqs = [
  {
    question: "Can I list my entertainment business on LocalBizs.com for free?",
    answer: "Yes, you can list your entertainment business on LocalBizs.com for free. Simply create an account, and follow the steps to set up your profile. You can include your business details, services, hours of operation, and photos to attract visitors.",
  },
  {
    question: "How can I update the activities or events offered at my entertainment venue?",
    answer: "To update the activities or events at your venue, log into your LocalBizs.com account, go to your profile settings, and update the 'Services/Activities' section. You can also update event schedules or promotional offers to keep your audience informed.",
  },
  {
    question: "Can I offer promotions and special offers on my LocalBizs.com profile?",
    answer: "Yes, you can post promotions, discounts, or special offers on your profile. This feature helps attract more visitors to your entertainment venue, especially during holidays or peak seasons.",
  },
  {
    question: "How do I manage customer reviews and feedback on LocalBizs.com?",
    answer: "LocalBizs.com allows you to manage customer reviews and feedback on your profile. You can respond to reviews, thank customers for positive feedback, and address any concerns or issues raised by your visitors.",
  },
  {
    question: "Can I list the amenities offered at my entertainment business?",
    answer: "Yes, you can list all the amenities offered at your venue, such as parking, wheelchair accessibility, snack bars, or VIP seating. This information helps potential visitors decide if your venue meets their needs.",
  },
  {
    question: "How do I manage bookings and reservations for activities or events?",
    answer: "LocalBizs.com integrates with your calendar to help manage bookings for activities, events, or private sessions. You can set your availability, accept online bookings, and send reminders to customers for their reservations.",
  },
  {
    question: "How can I ensure the safety and well-being of my guests during events or visits?",
    answer: "Ensuring safety is a priority. LocalBizs.com allows you to display safety measures you have in place, such as COVID-19 protocols, staff training, or special precautions for certain activities. Always update your profile with any new safety guidelines.",
  },
  {
    question: "Can I promote themed events or seasonal offerings on LocalBizs.com?",
    answer: "Yes, you can promote themed events, seasonal activities, or limited-time offerings on your profile. Whether it’s a Halloween event, Christmas specials, or summer music festivals, it helps attract more visitors during these times.",
  },
  {
    question: "How can I connect with other entertainment businesses or vendors?",
    answer: "LocalBizs.com provides features to network with other entertainment businesses and vendors. You can collaborate with event planners, entertainers, or local service providers to enhance your offerings and share resources.",
  },
  {
    question: "Can I list the age range or suitability for different activities?",
    answer: "Yes, it’s a good idea to mention the age range or suitability for your activities. Whether you offer family-friendly services, adult-only events, or activities for children, providing this information helps attract the right audience.",
  },
];
const professionalServicesFaqs = [
  {
    question: "How can I list my professional service on LocalBizs.com?",
    answer: "To list your professional service, create an account on LocalBizs.com and set up a profile for your business. Include key details such as your services, experience, portfolio, contact information, and operating hours to attract potential clients.",
  },
  {
    question: "Can I showcase my portfolio or past projects?",
    answer: "Yes, you can upload images, videos, and descriptions of your past projects or portfolio on your profile. This feature is helpful for services such as web design, videography, or architecture, where clients often prefer to see examples of your work.",
  },
  {
    question: "How do I attract clients looking for specific services like tax advice or web design?",
    answer: "Make sure to list all the services you offer in detail on your profile. Use keywords relevant to your field, such as 'tax preparation,' 'website development,' or 'financial planning.' LocalBizs.com will help potential clients find you based on their search criteria.",
  },
  {
    question: "Can I set up a booking system for consultations?",
    answer: "Yes, LocalBizs.com provides an option to manage appointments and consultations. You can set your availability, accept bookings online, and send reminders to clients for scheduled meetings.",
  },
  {
    question: "How do I manage customer reviews and feedback?",
    answer: "LocalBizs.com allows you to manage customer reviews on your profile. You can respond to reviews, thank clients for positive feedback, and address any concerns. Engaging with reviews can enhance your credibility and help attract new clients.",
  },
  {
    question: "Can I promote seasonal discounts or special offers?",
    answer: "Yes, you can add promotions, discounts, or special offers to your profile. This is especially useful for services with seasonal demand, such as tax services during tax season or videographers during wedding season.",
  },
  {
    question: "Is there a way to list the industries or clients I specialize in?",
    answer: "Yes, you can mention your specific areas of expertise, such as corporate clients, small businesses, or particular industries like real estate or healthcare. This information helps clients determine if your service is the right fit for their needs.",
  },
  {
    question: "How can I highlight my certifications, licenses, or qualifications?",
    answer: "LocalBizs.com allows you to display your professional certifications, licenses, and qualifications. This feature is particularly useful for regulated industries like financial services and tax preparation, where credentials matter to clients.",
  },
  {
    question: "Can I network with other professionals or service providers?",
    answer: "Yes, LocalBizs.com includes networking features that let you connect with other professionals. Collaborate with related service providers, such as advertising agencies working with web designers or architects partnering with real estate agents.",
  },
  {
    question: "How do I ensure that my contact details are visible and up-to-date?",
    answer: "Simply log in to your account and navigate to the 'Profile Settings' section to update your contact details. It’s important to keep this information current so clients can easily reach out to you.",
  },
];
const localBusinessFaqs = [
  {
    question: "How can I list my local business on LocalBizs.com?",
    answer: "To list your business, simply create an account on LocalBizs.com, go to the 'Add Business' section, and fill in your business details. You can include important information like services offered, hours of operation, location, and contact details to attract potential customers.",
  },
  {
    question: "Can I showcase photos of my business or services?",
    answer: "Yes, you can upload photos that showcase your business, whether it's rooms for rent, completed car repairs, classroom setups, or product samples. Adding visuals helps customers get a better understanding of what you offer.",
  },
  {
    question: "How do I attract people looking for specific services like apartments or tutoring?",
    answer: "To attract people searching for specific services, make sure to list all the services you provide and use relevant keywords. For example, include terms like '2-bedroom apartment,' 'auto repair,' or 'tutoring for kids.' This will help your business appear in relevant searches on LocalBizs.com.",
  },
  {
    question: "Can I update my hours or add special holiday hours?",
    answer: "Yes, you can update your business hours at any time by logging into your profile and adjusting the 'Business Hours' section. You can also add special holiday hours so customers know when you're open during peak times.",
  },
  {
    question: "How do I manage customer reviews for my business?",
    answer: "LocalBizs.com allows you to manage and respond to customer reviews on your profile. You can thank customers for positive feedback or address any concerns, which can help improve your reputation and build trust with potential clients.",
  },
  {
    question: "Can I list special services or features, like 'family-friendly' or 'pet-friendly'?",
    answer: "Yes, you can highlight any unique features or services that set your business apart, such as 'family-friendly,' 'wheelchair accessible,' or 'pet-friendly' amenities. This information helps customers know what to expect when they visit.",
  },
  {
    question: "How can I promote seasonal offers or discounts?",
    answer: "LocalBizs.com allows you to add promotions, seasonal discounts, or special offers to your profile. This is especially useful for businesses like hotels, car repair shops, or window companies, where seasonal demand may vary.",
  },
  {
    question: "Is there a way to show my business's safety measures or cleanliness standards?",
    answer: "Yes, you can include information about safety or cleanliness standards, such as COVID-19 precautions, for added reassurance. This is especially important for businesses like preschools, tutoring centers, and hotels.",
  },
  {
    question: "How do I provide contact information for customers to reach me directly?",
    answer: "Ensure your contact details, including phone number, email, and address, are clearly listed on your profile. Customers can then contact you directly to ask questions, schedule appointments, or book services.",
  },
  {
    question: "Can I list any credentials, certifications, or industry affiliations?",
    answer: "Yes, you can showcase any credentials, certifications, or affiliations that might build customer trust, such as licensed technicians for car repair shops or accredited teachers at a tutoring center.",
  },
];
const localServicesFaqs = [
  {
    question: "How do I list my local service business on LocalBizs.com?",
    answer: "To list your business, create an account on LocalBizs.com and navigate to the 'Add Business' section. Provide details like the services offered, contact information, hours of operation, and any certifications to help potential customers find your business.",
  },
  {
    question: "Can I include photos of my completed work or facilities?",
    answer: "Yes, you can upload photos to showcase completed jobs, before-and-after images, or facilities. Whether it's carpet cleaning results, tree trimming work, or a newly painted room, visuals help attract more customers.",
  },
  {
    question: "How can I communicate my business’s safety and health standards?",
    answer: "LocalBizs.com allows you to include information on health and safety standards, such as eco-friendly cleaning products, pest control safety measures, or COVID-19 precautions. This helps reassure customers about the quality and safety of your services.",
  },
  {
    question: "Can I promote seasonal services or discounts on my profile?",
    answer: "Yes, you can highlight any seasonal services or discounts, like special rates for carpet cleaning in the spring or discounts on tree services in fall. Updating your profile with promotions can attract more customers during peak times.",
  },
  {
    question: "How can I handle customer reviews and feedback on LocalBizs.com?",
    answer: "LocalBizs.com allows you to manage customer reviews by responding to feedback directly on your profile. Addressing reviews, whether positive or negative, helps build trust with potential clients and shows your commitment to customer satisfaction.",
  },
  {
    question: "Can I list emergency or after-hours services on my profile?",
    answer: "Yes, you can indicate if you offer emergency or after-hours services, which is especially useful for businesses like plumbers, garage door repair, or computer repair. This information helps customers know they can rely on you in urgent situations.",
  },
  {
    question: "How do I add my business’s location and service area?",
    answer: "You can specify your business address and service area radius in your profile. This is helpful for services like pest control, tree services, and plumbing, where customers often prefer local providers within a specific area.",
  },
  {
    question: "Can I provide a list of specialties or unique services?",
    answer: "Absolutely! You can highlight any specialties or unique services, such as eco-friendly carpet cleaning, vintage computer repair, or expert chimney inspections. This helps your profile stand out to customers looking for specific solutions.",
  },
  {
    question: "How do I provide my contact information so customers can easily reach me?",
    answer: "Make sure to include your phone number, email, and any other contact options on your profile. Clear contact information allows customers to reach out directly for quotes, bookings, or general inquiries.",
  },
  {
    question: "Can I showcase certifications, licenses, or memberships to build trust?",
    answer: "Yes, you can add any industry certifications, licenses, or memberships to your profile. Displaying credentials like licensed plumbers, certified pest control specialists, or insured painters can help reassure customers of your professionalism and quality.",
  },
];
// FAQSection Component
const FAQSection = ({ isRestaurant, isHealthOrDoctor,isLawyer ,isEntertainment,isProfessional,isLocalBusiness,isLocalService }) => {
  const [openIndex, setOpenIndex] = useState(null);

  // Select the appropriate FAQs based on the type (doctor or restaurant)

  const faqs = isRestaurant ? restaurantFaqs : isHealthOrDoctor ? doctorFaqs : isLawyer ? lawyerFaqs : isEntertainment ? entertainmentFaqs :isProfessional ? professionalServicesFaqs: isLocalBusiness ? localBusinessFaqs: isLocalService? localServicesFaqs: [];;

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="d-block">
      <div className="jbd-01">
        <div className="jbd-details">
          <h5 className="ft-bold fs-lg">Frequently Asked Questions</h5>

          <div className="d-block mt-3">
            <div id="accordion2" className="accordion">
              {faqs.map((faq, index) => (
                <div className="card" key={index}>
                  <div className="card-header" id={`h${index}`}>
                    <h5 className="mb-0">
                      <button
                        className={`btn btn-link ${openIndex === index ? "" : "collapsed"}`}
                        onClick={() => handleToggle(index)}
                        aria-expanded={openIndex === index}
                        aria-controls={`ord${index}`}
                      >
                        {faq.question}
                      </button>
                    </h5>
                  </div>

                  <div
                    id={`ord${index}`}
                    className={`collapse ${openIndex === index ? "show" : ""}`}
                    aria-labelledby={`h${index}`}
                    data-parent="#accordion2"
                  >
                    <div className="card-body">
                      {faq.answer}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
import React from 'react';

// Sample review data
const reviewsData = [
    {
        id: 1,
        name: "Kayla E. Claxton",
        date: "27 Oct 2019",
        location: "San Francisco, USA",
        rating: 5,
        comment: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.",
        imageUrl: "https://via.placeholder.com/400x400",
        likes: 12,
        dislikes: 1,
        loves: 7,
    },
    {
        id: 2,
        name: "Amy M. Taylor",
        date: "2 Nov 2019",
        location: "Liverpool, London UK",
        rating: 4,
        comment: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.",
        imageUrl: "https://via.placeholder.com/400x400",
        likes: 12,
        dislikes: 1,
        loves: 7,
    },
    {
        id: 3,
        name: "Susan C. Daggett",
        date: "10 Nov 2019",
        location: "Denver, United State",
        rating: 4,
        comment: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.",
        imageUrl: "https://via.placeholder.com/400x400",
        likes: 12,
        dislikes: 1,
        loves: 7,
    },
    // Add more reviews as needed
];

// Review Component
const ReviewItem = ({ review }) => {
    const { name, date, location, rating, comment, imageUrl, likes, dislikes, loves } = review;

    return (
        <div className="reviews-comments-item" key={review.id}>
            <div className="review-comments-avatar">
                <img src={imageUrl} className="img-fluid" alt={name} />
            </div>
            <div className="reviews-comments-item-text">
                <h4>
                    <a href="#">{name}</a>
                    <span className="reviews-comments-item-date">
                        <i className="ti-calendar theme-cl me-1"></i>{date}
                    </span>
                </h4>
                <span className="agd-location">
                    <i className="lni lni-map-marker me-1"></i>{location}
                </span>
                <div className="listing-rating">
                    {[...Array(5)].map((_, i) => (
                        <i key={i} className={`fas fa-star ${i < rating ? 'active' : ''}`}></i>
                    ))}
                </div>
                <div className="clearfix"></div>
                <p>{`"${comment}"`}</p>
                <div className="pull-left reviews-reaction">
                    <a href="#" className="comment-like active"><i className="ti-thumb-up"></i> {likes}</a>
                    <a href="#" className="comment-dislike active"><i className="ti-thumb-down"></i> {dislikes}</a>
                    <a href="#" className="comment-love active"><i className="ti-heart"></i> {loves}</a>
                </div>
            </div>
        </div>
    );
};

// Reviews List Component
const ReviewsList = () => {
    return (
        <div className="d-block">
            <div className="jbd-01">
                <div className="jbd-details mb-4">
                    <h5 className="ft-bold fs-lg">Recommended Reviews</h5>
                    <div className="reviews-comments-wrap">
                        {reviewsData.map((review) => (
                            <ReviewItem review={review} key={review.id} />
                        ))}
                        <ul className="pagination">
                            <li className="page-item">
                                <a className="page-link" href="#" aria-label="Previous">
                                    <span className="fas fa-arrow-circle-left"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                            </li>
                            {/* Add pagination items dynamically as needed */}
                            <li className="page-item active"><a className="page-link" href="#">1</a></li>
                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item">
                                <a className="page-link" href="#" aria-label="Next">
                                    <span className="fas fa-arrow-circle-right"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewsList;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SearchBarBanner from './SearchBarBanner';
import ReviewsSection from './ReviewsList';
import BookingForm from './BookingForm';
import AuthorBox from './AuthorBox';
import BusinessInfo from './BusinessInfo';
import RelatedListings from './RelatedListings';
import FeaturedSlider from './FeaturedSlider';
import AboutBusiness from './AboutBusiness';
import BusinessMenu from './BusinessMenu';
import AmenitiesAndMore from './AmentiesAndMore';
import FAQSection from './FAQSection';
import LocationHours from './LocationHours';
import ContactInfo from './ContactInfo';
import DarkHeader from './DarkHeader';
import OrderForm from './OrderForm';
import AppointmentForm from './AppointmentForm';
import BeautifulSpinner from './BeautifulSpinner';

const SingleListingTemplate = () => {
    const { location, name, businessDetailID } = useParams();
    const [listingData, setListingData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

     const apiUrl = `https://api.localbizs.com/api/BestBusiness/${businessDetailID}`;
    // const apiUrl = `http://localhost:5294/api/BestBusiness/${businessDetailID}`;




    const handleAppointmentSubmit = (formData) => {
        console.log('Appointment Data:', formData);
    };

    useEffect(() => {
        const fetchListing = async () => {
            try {
                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                });

                if (response.ok) {
                    const data = await response.json();
                    setListingData(data);
                } else {
                    setError('Failed to fetch listing data');
                }
            } catch (error) {
                setError(`Error fetching listing data: ${error.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchListing();
    }, [businessDetailID]);

    if (loading) {
        return (
            <div>
                <BeautifulSpinner /> {/* Show spinner while loading */}
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const isHealthOrDoctorSector =
        listingData.sectorName === 'Best Doctors' || listingData.sectorName === 'Best Health';
    const isEntertainmentSector = listingData.sectorName === 'Best Entertainment';

    const isRestaurantSector =
        listingData.sectorName === 'Best Restaurants' || listingData.sectorName === 'Best Speciality Cuisine';
    const isLawyer = listingData.sectorName === 'Best Lawyers';
    const isEntertainment = listingData.sectorName === 'Best Entertainment'; // Added entertainment sector check



    return (
        <div id="main-wrapper">
            <DarkHeader />
            <SearchBarBanner
                dummyBannerData={{
                    imageSrc: "/images/images1/" + listingData.image1_URL || '',
                    // title: `${listingData?.name || ''} ${listingData?.degree ? listingData.degree + " " : ""}${listingData?.clinicname || ""}`.trim(),
                    title: listingData.businessName,
                    reviews: listingData.reviewCount || 0,
                    rating: listingData.rating || 0,
                    priceRange: 3,
                    claimed: true,
                    categories: [listingData.professional],
                    status: listingData.workingHours ? 'Open' : 'Closed',
                    hours: listingData.workingHours,
                }}
            />

            <section className="py-5 position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                            {listingData.image1_URL && (
                                <FeaturedSlider
                                    slider={{
                                        sliderData: [
                                            { url: "/images/images1/" + listingData.image1_URL, alt: '' },
                                            { url: "/images/images2/" + listingData.image2_URL, alt: '' },
                                            { url: "/images/images3/" + listingData.image3_URL, alt: '' },
                                        ],
                                    }}
                                />
                            )}
                            <AboutBusiness description={{ aboutContent: listingData.description }} />
                            {/* <BusinessMenu menu={listingData.specialties} /> */}
                            {/* <AmenitiesAndMore amenities={{ amenitiess: [listingData.specialties] }} /> */}
                            <AmenitiesAndMore
                                amenities={
                                    listingData.specialties
                                        .replace("CONDITIONS:", "") // Remove the prefix
                                        .split(",") // Split by commas
                                        .map((s) => s.trim()) // Trim each item
                                }
                            />
                            <FAQSection isRestaurant={isRestaurantSector} isHealthOrDoctor={isHealthOrDoctorSector} isLawyer={isLawyer} isEntertainment={isEntertainment} />;                            <ReviewsSection reviews={listingData.reviews || []} />
                            <LocationHours hours={listingData.workingHours} />
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                            {isHealthOrDoctorSector ? (
                                <AppointmentForm
                                    initialDate="2024-10-24"
                                    onSubmit={handleAppointmentSubmit}
                                />
                            ) : isEntertainmentSector ? (
                                <BookingForm bookingData={{ checkIn: '2024-09-28' }} />

                            ) : (
                                <>
                                    <OrderForm
                                        deliveryFee={listingData?.deliveryFee || 0.99}
                                        minOrder={listingData?.minOrder || 0}
                                        deliveryTime={listingData?.deliveryTime || '35-45'}
                                    />
                                    <BookingForm bookingData={{ checkIn: '2024-09-28' }} />
                                </>
                            )}

                            <BusinessInfo
                                business={{
                                    website: listingData.websiteURL,
                                    email: listingData.email,
                                    phone: listingData.phoneNumber,
                                    address: listingData.address,
                                }}
                            />
                            <AuthorBox
                                author={{
                                    name: listingData.Name,
                                    location: `${listingData.cityName}, ${listingData.stateName}`,
                                    image: "/images/images2/" + listingData.image2_URL,
                                    ratings: listingData.rating,
                                }}
                            />
                            <ContactInfo
                                contact={{
                                    phone: listingData.phoneNumber,
                                    email: listingData.email,
                                    address: listingData.address,
                                }}
                            />
                            <RelatedListings
                                relatedListings={[
                                    { id: 1, title: 'Cozy Cottage', description: 'A cozy cottage in the woods.' },
                                    { id: 2, title: 'Beach House', description: 'A beautiful beach house with ocean views.' },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default SingleListingTemplate;
import React, { useState } from "react";

const ReadMore = ({ text }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const maxLength = 440; // Define the max length for collapsed text
    const shouldShowReadMore = text.length > maxLength;

    return (
        <div className="vrt-list-features mt-2 mb-2">
            <p className="vrt-qgunke">
                {isExpanded || !shouldShowReadMore
                    ? text
                    : `${text.slice(0, maxLength)}...`}
                {shouldShowReadMore && (
                    <span
                        onClick={toggleReadMore}
                        className="read-more-toggle"
                        style={{ color: "#007bff", cursor: "pointer", marginLeft: "5px" }}
                    >
                        {isExpanded ? "Read Less" : "Read More"}
                    </span>
                )}
            </p>
        </div>
    );
};

export default ReadMore;